/* Global CSS */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500");
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600&display=swap');

$grid-breakpoints: (
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
  xxxl: 1540
);

$font-family-base: "Noto Sans";

:root {
  --toastify-color-error: #E15C5C;
  --toastify-color-success: #15A683;
  --toastify-toast-width: 480px;
}

.active-color {
  color: #333
}

.active-color::-webkit-input-placeholder {
  color: #999;
}

.active-color:-moz-placeholder {
  color: #999;
}

.active-color::-moz-placeholder {
  color: #999;
}

.active-color:-ms-input-placeholder {
  color: #999;
}

.Toastify__close-button {
  opacity: 1;
}

.container {
  max-width: 1188px;
  margin: 0 auto;
}

.section_header {
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}

.sub_section_header {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
}

.label_header {
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  letter-spacing: -0.2px;
  color: #253858;
  margin-left: 10px;
  margin-top: 2px;
}

.information_label {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #253858;
}

.information_content {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #253858;
}

.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #999999;

}

.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;

}

.Toastify__toast-body {
  font-weight: 400;
  margin-left: 4px;
  height: 100px;
  width: 100px;
}

.Toastify__close-button>svg {

  height: 14px;
  width: 14px;
  margin-top: 16px;
  margin-right: 13px;
}

.Toastify__toast-container--top-center {
  left: 45%;
}

$font-size-base: 1rem;


html body {
  font-family: $font-family-base;
  // font-weight: 400;
  font-size: $font-size-base;
  // color: #5a6168;
  // -webkit-font-smoothing: antialiased;
}

html body a {
  // color: #4378ff;
  margin: 0;
  text-decoration: none;
  font-family: 'noto sans';
}

a:hover {
  text-decoration: none !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  width: 30vw;
  border-radius: 4;
  background: #42AD47 !important;
  color: #FFFFFF;
  //styleName: Body Normal 16pts;
  font-family: 'Noto Sans';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}

.Toastify__toast-body {
  height: 5%;
  width: 100%;
}

// label {
//   color: #2a2a3e;
// }

// h1,
// h2,
// h3 {
//   font-family: "Roboto Condensed", sans-serif;
//   font-weight: 400;
//   line-height: 37px;
//   color: #5a6168;
// }

body h5 {
  font-family: "noto sans";
  // font-weight: 300;
  // font-size: 16px;
}

// body h4,
// body .h4 {
//   font-size: 20px;
// }

html body h1 {
  font-size: 32px;
}

html body h3 {
  font-size: 20px;
}

html ul {
  padding-left: 0;

  li {
    list-style: none;
    margin: 0;
  }
}

.form-control:focus {
  border-color: #99A6B9 !important
}

.tvs-breadcrumb {
  li:not(:last-child) {
    &:after {
      content: url('./components/elements/arrowIcon.svg');
      /* Replace with the actual path to your image */
      display: inline-block;
      margin-left: 12px;
      height: 24px;
      vertical-align: middle;
      /* Aligns the image with the text */
    }
  }

  li,
  li {
    list-style: none;
  }

  li a {
    color: #666666;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  li a:hover {
    color: #333;
    font-weight: 400;
  }

  li:last-child {
    a {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      pointer-events: none;
      cursor: default;
    }
  }
}

button,
button.btn {
  font-family: "Noto Sans";

  &:focus,
  &:hover,
  &:active {
    box-shadow: none;
  }
}

form button.btn {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

}