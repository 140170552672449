.dashboard-main {
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 100%;
}

.header-container {
	height: 60px;
	flex-shrink: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10;
	background-color: #fff;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.dashboard-layout {
	display: flex;
	flex: 1;
	margin-top: 60px;
	overflow: hidden;
}

.sidebar {
	width: 250px;
	flex-shrink: 0;

	@media (max-width: 820px) {
		width: 0px;
	}
}

.content-area {
	flex-grow: 1;
	overflow-y: auto;
	padding: 70px 24px 24px 24px;
	box-sizing: border-box;
	background-color: #F4F5F7;

	@media only screen and (max-width: 600px) {
		padding: 0;
	}
}

.bzHKCU,
.kFAjCY,
.dixaok,
.hVmsnv,
.gwRVdc {
	width: auto !important;
}

.dQGtbG,
.css-19kzrtu {
	@media only screen and (max-width: 600px) {
		padding: 0 !important;
	}
}

.NPoOD,
.cuyvMU,
.cxApzA,
.hPpTgG,
.iOUMBe,
.dlWZdU,
.cOpwTn,
.foCBxX {
	@media only screen and (max-width: 600px) {
		padding-left: 0 !important;
	}
}

.heIxvT,
.JIceA,
.iMmull,
.TuubL {
	background-color: transparent !important;
}